<template>
  <div class="view-create-battle">
    <CompHeader title="创建对战">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="float-bottom-box">
      <div class="float-bottom-header flex-b">
        <div class="people-num-box flex-s">
          <div
            @click="activePeopleNum = item.value"
            v-for="item in peopleNumList"
            :key="item.value"
            class="item flex-c">
            <div style="background: #332a19" class="rem-password-checkbox">
              <div
                v-show="activePeopleNum === item.value"
                class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div style="color: #fff" class="rem-password-text">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div @click="choosedBoxIdList = []" class="clear-btn flex-c">
          清空所有
        </div>
      </div>
      <div class="float-bottom-center">
        <div class="scroll-box flex-s">
          <div
            v-for="(item, index) in renderChooseBoxList"
            :key="index"
            class="item">
            <div class="img flex-c">
              <img class="w100" :src="item.intact_cover" alt="" srcset="" />
            </div>
            <div class="coin">
              <CompCoin
                :styleList="[0.1, 0.1, 0.1, 0.02]"
                :num="item.game_bean" />
            </div>
            <div class="close-btn"></div>
          </div>
        </div>
      </div>
      <div class="float-bottom-footer flex-b">
        <div class="left-box flex-s">
          <div class="num">已选 10 件</div>
          <div class="coin">
            <CompCoin :styleList="[0.14, 0.14, 0.14]" num="1.00" />
          </div>
        </div>
        <div class="right">
          <div @click="$router.push('/battleRoom')" class="create-btn flex-c">
            确认创建
          </div>
        </div>
      </div>
    </div>
    <div class="create-battle-header flex-c">
      <div class="create-battle-center flex-s">
        <div
          @click="activeBattleType = item.value"
          v-for="item in battleTypeList"
          :key="item.value"
          class="btn-item btn-item1 flex-c"
          :class="{ 'btn-item2': activeBattleType === item.value }">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="create-battle-type-list flex-s">
      <div v-for="item in 10" :key="item" class="item flex-c">
        盲盒分类
        <div class="item-active"></div>
      </div>
    </div>
    <div class="create-battle-box-list flex-s">
      <div
        @click="chooseBoxOnClick(item)"
        v-for="item in battleBoxList"
        :key="item.id"
        class="item">
        <div class="goods-img">
          <img class="wh100" :src="item.intact_cover" alt="" srcset="" />
        </div>
        <div class="goods-name">商品名称</div>
        <div class="goods-money flex-c">
          <CompCoin num="122.12"></CompCoin>
        </div>
        <div v-if="choosedBoxIdList.includes(item.id)" class="float-item">
          <div class="float-item-bottom flex-b">
            <div @click.stop="chooceOnclickDown(item)" class="left"></div>
            <div class="center">{{ chooceListNum[item.id] }}</div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBattleBoxListApi } from "@/network/api.js";
export default {
  name: "createBattle",
  data() {
    return {
      battleTypeList: [
        { name: "创建欧皇", value: "1" },
        { name: "创建非酋", value: "2" },
      ],
      activeBattleType: "1",
      peopleNumList: [
        { name: "创建双人", value: "2" },
        { name: "创建三人", value: "3" },
        { name: "创建四人", value: "4" },
      ],
      activePeopleNum: "2",
      battleBoxList: [],
      filterList: [
        { name: "全部", value: "0" },
        { name: "玩家", value: "1" },
        { name: "主播", value: "2" },
      ],
      activeFilter: "1",
      choosedBoxList: [],
      choosedBoxIdList: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    chooceListNum() {
      const obj = {};
      this.choosedBoxIdList.forEach((item) => {
        const itemStr = item + "";
        if (Object.keys(obj).includes(itemStr)) {
          obj[itemStr] = obj[itemStr] * 1 + 1;
        } else {
          obj[itemStr] = 1;
        }
      });
      return obj;
    },
    renderChooseBoxList() {
      const array = [];
      this.choosedBoxIdList.forEach((item) => {
        this.choosedBoxList.forEach((vItem) => {
          if (vItem.id === item) {
            array.push(vItem);
          }
        });
      });
      console.log(55555, array);

      return array;
    },
  },
  methods: {
    init() {
      this.getBattleBoxListFun();
      this.createRoomFun();
    },
    chooseBoxOnClick(item) {
      this.choosedBoxIdList.push(item.id);
      if (!this.choosedBoxList.some((vItem) => vItem.id === item.id)) {
        this.choosedBoxList.push(item);
        return;
      }
      console.log(123, this.choosedBoxIdList, this.choosedBoxList);
    },
    async getBattleBoxListFun() {
      try {
        const res = await getBattleBoxListApi();
        if (res?.data?.code === 200) {
          this.battleBoxList = res?.data?.data;
        } else {
          this.$message.error("获取对战宝箱列表失败");
        }
        console.log("对战宝箱列表", res);
      } catch (error) {
        this.$message.error(error);
      }
    },
    //箱子减少
    chooceOnclickDown(item) {
      console.log(3333, item);
      let reverceArr = this.choosedBoxIdList.reverse();
      const index = reverceArr.indexOf(item.id);
      reverceArr.splice(index, 1);
      this.choosedBoxIdList = reverceArr.reverse();
    },
    createRoomFun() {
      console.log("创建对战");
    },
  },
};
</script>

<style lang="scss">
.view-create-battle {
  background: #363022;
  min-height: 100vh;
  padding: 0.58rem 0rem 1.82rem;
  .float-bottom-box {
    width: 100%;
    // height: 1.82rem;
    background: #363022;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-top: 0.02rem solid #ffd71d;
    font-size: 0.12rem;
    .float-bottom-header {
      width: 100%;
      height: 0.52rem;
      padding: 0 0.2rem;
      .people-num-box {
        .item {
          margin-right: 0.16rem;
        }
      }
      .clear-btn {
        padding: 0.06rem 0.1rem;
        background: #5c4a25;
        color: #e7b400;
      }
    }
    .float-bottom-center {
      width: 100%;
      height: 0.8rem;
      //   background: red;
      position: relative;
      .scroll-box {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        position: relative;
        padding: 0.12rem 0.15rem;
        background: #332a19;
        .item {
          width: 0.44rem;
          margin-right: 0.31rem;
          position: relative;
          .img {
            width: 0.44rem;
            height: 0.44rem;
          }
          .coin {
          }
          .close-btn {
            width: 0.16rem;
            height: 0.16rem;
            right: -0.1rem;
            top: -0.04rem;
            @include bgImg("battle-close-btn");
            position: absolute;
          }
        }
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 0.12rem;
        height: 100%;
        content: "";
        @include bgImg("battle-left-icon");
        z-index: 1;
      }
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 0.12rem;
        height: 100%;
        content: "";
        @include bgImg("battle-right-icon");
      }
    }
    .float-bottom-footer {
      width: 100%;
      height: 0.5rem;
      padding: 0 0.2rem;
      background: #4f4424;
      .left-box {
        .num {
          margin-right: 0.2rem;
          font-size: 0.12rem;
        }
        .coin {
          font-weight: 500;
        }
      }
      .right {
        .create-btn {
          width: 0.96rem;
          height: 0.3rem;
          font-size: 0.14rem;
          font-weight: 500;
          color: #312f2d;
          @include bgImg("btn-active-bg");
        }
      }
    }
  }
  .create-battle-header {
    margin-bottom: 0.12rem;
    .create-battle-center {
      padding: 0.04rem 0.07rem;
      background: #161514;
      .btn-item {
        width: 0.88rem;
        height: 0.3rem;
        font-weight: 500;
        font-size: 0.12rem;
        margin-right: 0.1rem;
        @include bgImg("record-table");
        &:last-child {
          margin-right: 0;
        }
      }
      .btn-item2 {
        @include bgImg("record-actable");
      }
    }
  }
  .create-battle-type-list {
    padding: 0 0 0 0.2rem;
    margin-bottom: 0.12rem;
    overflow-x: auto;
    .item {
      position: relative;
      width: 0.96rem;
      height: 0.36rem;
      font-size: 0.12rem;
      flex-shrink: 0;
      margin-right: 0.12rem;
      @include bgImg("classify-bg");
      .item-active {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include bgImg("border-bg");
      }
    }
  }
  .create-battle-box-list {
    flex-wrap: wrap;
    padding: 0 0.2rem;
    .item {
      padding: 0 0.12rem;
      width: 1.07rem;
      //   height: 1.07rem;
      margin-right: 0.07rem;
      margin-bottom: 0.08rem;
      padding-bottom: 0.06rem;
      position: relative;
      @include bgImg("box-item-bg");
      .goods-img {
        width: 0.8rem;
        height: 0.68rem;
        margin-bottom: 0.01rem;
      }
      .goods-name {
        margin-bottom: 0.02rem;
        text-align: center;
        font-size: 0.12rem;
      }
      .goods-money {
      }
      .float-item {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include bgImg("battle-box-choosed");
        .float-item-bottom {
          position: absolute;
          width: 100%;
          // height: 100%;
          bottom: 0;
          left: 0;
          padding: 0.01rem 0.02rem;
          font-weight: 500;
          font-size: 0.12rem;
          color: #040404;
          .left {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("down-icon");
          }
          .center {
            flex: 1;
            text-align: center;
          }
          .right {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("up-icon");
          }
        }
      }
    }
    .item:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
